<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="row justify-content-center">
          <div class="col-md-6">
              <p class="font-semibold">List Chat : </p>
            <div class="p-3 rounded-lg shadow cursor-pointer" 
            @click="$router.push(`/admin/chat/detail?id_pengirim=${item.data[0].id_pengirim}`)"
            v-if="!listuser.includes(item.username)"
            v-for="(item, index) in listchat" :key="index+'listchat'">
                <button type="button" class="btn btn-sm btn-danger   waves-effect float-right">{{item.unread}}</button>
                {{item.username}} 
            </div>
          </div>
        </div>
     <div class="d-block d-sm-none"><br><br><br><br></div>

    </div>
</template>
<script>
import autophp from '@/plugins/autophp_kasir';
let sdb = new autophp();
import firebase from 'firebase'
let rdb = firebase.database();
export default{
    layout:'app_shop_admin',
    data(){
        return{
            listchat:[],
            uniq:'',
            listuser:[]
        }
    },
    watch:{
        uniq(){
            this.getListComment();
        }
    },
    mounted() {
        let that=this;
          rdb.ref('/chat/admin').on('value',(e)=>{ //read
              this.getListComment();
          }) 
          sdb.collection("tbuser",false).doc().select(`select * from tbuser where level!='user'`).then(res => {
              this.listuser=res.map(e=>{
                  return e.username
              })
              this.$forceUpdate();
          });
          
    },
    methods: {
        getListComment(){
            sdb.collection("app_kasir_chat",false).doc().select(`select * from app_kasir_chat`).then(res => {
                var unique = [...new Set(res.map((item) => item['username']))];
                unique=unique.map(e=>{
                    return {
                        username:e
                    }
                })
                unique.forEach((e,i)=>{ // [{id:1,status:'masuk'},{id:2,status:'keluar'}]
                    unique[i].data=[]; // [{id:1,status:'masuk',data:[]},{id:2,status:'keluar',data:[]}]
                    res.forEach(k=>{ // loop data yang ingin di join dengan uniq key 
                        if(e.username==k.username){ // gabungkan semua data di datanya yang status keluar dan masuk
                            unique[i].data.push(k); 
                        }
                    })
                })
                unique.forEach(e=>{
                    e.unread=e.data.filter(e=>{
                        if(e.dibaca=='false' && e.penerima=='admin'){
                            return e;
                        }
                    }).length
                })
                this.listchat=unique;
                console.log(unique)
                this.$forceUpdate();
            });
        }
    },
}
</script>